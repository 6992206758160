.root {
  height: 100vh;
  padding: 8px;
  :global {
    .ant-tabs-content-holder {
      overflow: auto;
    }
  }
}
body {
  margin: 0;
}
