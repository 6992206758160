.root {
  height: 100%;
  .header {
    height: 60px;
    padding: 20px;
  }
  .body {
    height: calc(100% - 60px);
  }
  .row {
    display: flex;
    height: 100%;
  }
  .col {
    // width: 90%;
    flex: 9;
    height: 100%;
    overflow: auto;
    padding: 20px;
  }
  .col2 {
    // width: 10%;
    flex: 1;
    height: 100%;
    overflow: auto;
    padding-right: 10px;
  }
  .card {
    height: 100%;
  }
  .num {
    padding-right: 20px;
  }
}
